<template>
  <div class="user-layout">
    <Topbar v-if="!hideTopBar" class="user-header shadow-40">
      <template v-slot:title>
        <slot name="title" />
      </template>
      <template>
        <slot name="topbar" />
      </template>
      <template v-slot:prepend-icons>
        <slot name="topbar-prepend-icons" />
      </template>
    </Topbar>
    <Sidebar
      class="user-side-nav"
      :items="routes"
      :activeItem="sidebarActiveItem"
    ></Sidebar>
    <div class="user-main">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Sidebar from '../Sidebar/Sidebar';
import Topbar from '../Topbar/Topbar';
export default {
  name: 'LayoutBase',

  components: { Sidebar, Topbar },
  props: {
    routes: { type: Array, default: null },
    hideTopBar: { type: Boolean, default: false },
    sidebarActiveItem: { default: false },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/custom/_layout';
</style>
