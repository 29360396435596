<template>
  <aside class="sidebar d-flex flex-column bg-black">
    <!-- Sidebar Header (will become topbar on mobile view ) -->
    <div
      class="
        sidebar-header
        p-1 p-md-5
        d-flex
        align-items-center
        justify-content-between justify-content-md-center
        max-vw-100
      "
    >
      <!-- Header Logo -->
      <img src="@/assets/pocketyard_logo.svg" alt="logo" width="70" class="p-2 p-xl-0 d-block d-md-none"/>
      <img src="@/assets/pocketyard_logo.svg" alt="logo" width="100" class="p-2 p-xl-0 d-none d-md-block"/>
      <!--  Mobile Hedader Menu (all mobiles are to be refactored) -->
      <AppIcon
        name="hamburger_menu"
        class="text-white d-md-none mr-2"
        @click="showMobileMenu = true"
      ></AppIcon>
    </div>

    <!-- Main sidebar menu -->
    <div
      class="
        sidebar-mainmenu
        h-100
        shadow-80
        d-flex
        flex-column flex-shrink-1 flex-grow-1
        align-items-stretch
        overflow-auto
        bg-black
      "
      :class="{ 'show-mobile': showMobileMenu }"
    >
      <!-- Mobile Only Menu Close Icon-->
      <AppIcon
        name="close"
        class="m-2 mt-4 ml-auto text-white d-md-none"
        @click="showMobileMenu = false"
      ></AppIcon>

      <!-- Actual Menu -->
      <div class="sidebar-mainmenu-items h-100">
        <div v-for="(item, index) in items" :key="`sidebar-item-${index}`">
          <!-- MainItem -->
          <SidebarItem
            v-if="isRoleAllowed(item.allowedGroups)"
            :text="item.text"
            :icon="item.icon"
            :route="item.route + (checkDefaultSubRoute(item) || '')"
            :active="isActive(item) || isSubMenuOpen(item, index)"
            :notifications="item.notifications"
            :hasSubMenu="hasSubMenu(item)"
            @click="sidebarItemClick(index)"
          ></SidebarItem>
          <!-- Optional Subitems -->
          <transition name="sidebar-mainmenu-items-subitems">
            <div
              class="sidebar-mainmenu-items-subitems"
              v-if="isSubMenuOpen(item, index)"
            >
              <SidebarItem
                v-for="(subItem, subindex) in item.subRoutes"
                :key="`sidebar-${index}-subitem-${subindex}`"
                :text="subItem.text"
                :route="computedRoute(item, subItem)"
                :is-sub-item="true"
                :active="
                  activeItem.children
                    ? activeItem.children.includes(subItem.activeName)
                    : subItem.exact
                    ? $route.path ===
                      (subItem.overrideParentRoute || item.route) +
                        subItem.route
                    : $route.path.includes(subItem.route)
                "
              ></SidebarItem>
            </div>
          </transition>
        </div>
      </div>
      <!-- Sidebar Profile -->
      <SidebarProfile class="sidebar-mainmenu-profile"></SidebarProfile>
    </div>

    <!-- Mobile Clickable Backdrop Mask -->
    <div
      class="show-mobile-mask d-md-none"
      :class="{ show: showMobileMenu }"
      @click="showMobileMenu = false"
    ></div>
  </aside>
</template>

<script>
import AppIcon from '@/shared/elements/AppIcon.vue';
import SidebarItem from './SidebarItem.vue';
import SidebarProfile from './SidebarProfile.vue';
import { mapGetters } from 'vuex';
import { isRoleAllowed } from '@/router/AuthorizationGate';

export default {
  components: { SidebarItem, SidebarProfile, AppIcon },

  props: {
    items: {
      type: Array,
      default: () => [{ route: '', text: '', icon: '', exact: '' }],
    },
    activeItem: { default: false },
  },

  data() {
    return {
      showMobileMenu: false,
      currentSubMenu: null,
    };
  },

  computed: {
    ...mapGetters('auth', ['user']),
    // current active route from the items prop
    currentRoute() {
      const POSSIBLE_ROUTES = this.items.filter((route) => {
        return this.$route.path.includes(route.route);
      });

      for (let i = 0; i < POSSIBLE_ROUTES.length; i++) {
        const ROUTE = POSSIBLE_ROUTES[i];
        if (ROUTE.exact && ROUTE.route !== this.$route.path) {
          continue;
        } else {
          if (ROUTE.subRoutes) {
            ROUTE.subRouteDefault =
              ROUTE.subRoutes.find((subRoute) => subRoute.default)?.route || '';
          }
          return ROUTE;
        }
      }
      return false;
    },
  },

  methods: {
    isRoleAllowed,
    checkDefaultSubRoute(ROUTE) {
      if (ROUTE.subRoutes) {
        const subRouteDefault =
          ROUTE.subRoutes.find((subRoute) => subRoute.default)?.route || '';

        if (subRouteDefault === -1) {
          return null;
        } else {
          return subRouteDefault;
        }
      } else {
        return null;
      }
    },
    computedRoute(item, subItem) {
      return (subItem.overrideParentRoute || item.route) + subItem.route;
    },
    isSubMenuOpen(item, index) {
      return this.isActive(item) || this.currentSubMenu === index;
    },
    hasSubMenu(item) {
      return !!item.subRoutes;
    },
    isActive(item) {
      return this.activeItem.value
        ? item.activeName === this.activeItem.value
        : item.text === this.currentRoute.text;
    },
    sidebarItemClick(index) {
      if (this.currentSubMenu === index) {
        this.currentSubMenu = null;
        return;
      }

      this.currentSubMenu = index;
    },
  },
};
</script>

<style lang="scss" scoped>
@use "sass:math";
@import '@/scss/theme/_breakpoints';

.sidebar {
  // Height on becoming a mobile header
  @include media-md('below') {
    height: 70px;
  }
  &-mainmenu {
    // width of menu on desktop view
    $menu-width: (math.div(100vw, 12) * 2);
    width: $menu-width;
    // properties for menu as mobile drawer
    // (this could actually just convert to conditional rendering of AppDrawer)
    @include media-md('below') {
      position: absolute;
      z-index: 1023;
      right: 0;
      width: 0;
      transition: width 0.3s;
      &.show-mobile {
        width: 70vw;
      }
    }
    &-items {
      overflow: auto;

      &-subitems {
        max-height: 500px;
        overflow: hidden;

        // transition class
        &-enter-active,
        &-leave-active {
          transition: max-height 0.2s ease-in-out, opacity 0.3s ease-in-out;
        }
        &-enter,
        &-leave-to {
          opacity: 0;
          max-height: 0;
        }
      }
    }
  }

  .show-mobile-mask {
    position: fixed;
    z-index: 1021;
    width: 100vw;
    height: 100vh;
    background-color: rgba(17, 17, 17, 0.082);
    display: none;
    &.show {
      display: block;
    }
  }
}

.max-vw-100 {
  max-width: 100vw;
}
</style>
