<template>
  <span
    class="
      p-2 p-xl-4
      pb-3
      pt-3
      d-flex
      font-weight-normal
      text-body-3 text-decoration-none
    "
    :class="[
      isSubItem ? 'sidebar-subitem' : 'sidebar-item',
      { 'sidebar-active': active },
    ]"
    @click="handleClick"
  >
    <!-- icon -->
    <AppIcon
      :name="icon"
      :size="24"
      class="sidebar-item-icon mr-2 mr-xxl-4"
    ></AppIcon>
    <!-- text -->
    <span
      v-if="text.length > 0"
      class="sidebar-item-text text-body-3 text-md-caption text-xl-body-3"
      >{{ text }}</span
    >
    <slot v-else></slot>
    <!-- <div
      v-if="notifications > 0"
      class="
        sidebar-notification
        text-body-3
        ml-auto
        bg-danger-light-1
        rounded-circle
        d-flex
        align-items-center
        justify-content-center
      "
    >
      <p class="m-0 text-white">{{ notifications | notificationText }}</p>
    </div> -->
  </span>
</template>

<script>
/* eslint-disable */
import AppIcon from '@/shared/elements/AppIcon.vue';
export default {
  components: { AppIcon },
  name: 'SidebarItem',

  props: {
    route: { type: String, default: '' },
    text: { type: String, default: '' },
    icon: { type: String, default: '' },
    active: { type: Boolean, default: false },
    isSubItem: { type: Boolean, default: false },
    notifications: { type: Number, default: 0 },
    hasSubMenu: { type: Boolean, default: false },
  },

  filters: {
    notificationText(value) {
      if (value.toString().length > 1) {
        return '9+';
      } else {
        return value;
      }
    },
  },

  methods: {
    handleClick() {
      if (this.hasSubMenu) {
        this.$emit('click');
        return;
      }

      let currentPath = this.$router.history.current.path;

      if (currentPath.charAt(currentPath.length - 1) === '/') {
        currentPath = currentPath.slice(0, -1);
      }

      if (this.route === currentPath) {
        this.$router.go()
      } else {
        this.$router.push(this.route);
      }

    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/theme/_colors';
.sidebar {
  &-item,
  &-subitem {
    cursor: pointer;
    transition: background-color 0.2s;
    @extend .text-white;

    &:hover {
      @extend .bg-black-light-1;
    }

    &.sidebar-active,
    &.sidebar-active:hover {
      .sidebar-item-text,
      .sidebar-item-icon {
        @extend .text-primary;
      }
    }
  }

  &-item {
    &.sidebar-active,
    &.sidebar-active:hover {
      @extend .bg-black-light-1;
    }
  }

  &-subitem {
    .sidebar-item-icon {
      opacity: 0;
    }
  }

  &-notification {
    width: 30px;
    height: 30px;
    color: white !important;
  }
}
</style>
