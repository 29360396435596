import Vue from 'vue';

const toTitleCase = (str) => {
  if (!str) {
    return '';
  }

  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const toMoney = (str) => {
  if (!str && str !== 0) {
    return '';
  }

  return parseFloat(str.toFixed(2));
};

Vue.filter('toTitleCase', toTitleCase);

Vue.filter('toMoney', toMoney);

export default {
  filters: {
    toTitleCase,

    toMoney,
  },
};
