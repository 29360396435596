<template>
  <div
    class="
      sidebar-profile
      p-2
      pt-3
      pb-3
      w-100
      flex-shrink-0
      overflow-hidden
      border-top border-gray-dark-2
    "
  >
    <!-- Profile -->
    <router-link
      to="/account"
      class="
        d-flex
        justify-content-between
        align-items-center
        text-decoration-none
      "
    >
      <!-- image -->
      <div class="sidebar-profile-picture d-flex align-items-center">
        <AppMediaViewer
          class="rounded-circle"
          width="80%"
          :src="profile.profile_picture"
        ></AppMediaViewer>
      </div>
      <!-- description -->
      <div class="sidebar-profile-detail p-2 w-75 text-white flex-shrink-1">
        <span
          class="
            text-body-3 text-md-caption text-xl-body-3
            d-block
            font-weight-normal
            m-0
            text-truncate
            w-100
          "
        >
          {{ `${profile.first_name || ''} ${profile.last_name || ''}` }}
        </span>
        <span
          class="
            text-body-3 text-md-caption text-xl-body-3
            d-block
            font-weight-lighter
            m-0
            text-truncate
            w-100
          "
        >
          ({{ userType | toTitleCase }})
        </span>
      </div>
      <!-- Settings -->
      <div class="d-block d-md-none d-xl-block">
        <AppIcon hoverable name="settings" class="text-white"></AppIcon>
      </div>
    </router-link>

    <!-- Logout -->
    <AppBtn
      text
      color="white"
      class="w-100 mt-md-3 p-2"
      @click="confirmLogout = true"
    >
      <div class="w-100 d-flex align-items-center">
        <AppIcon
          reverse
          name="logout"
          size="32"
          class="ml-n2 ml-xl-0"
        ></AppIcon>
        <span class="m-0 ml-4 pl-1 text-body-3 text-md-caption text-xl-body-3"
          >Logout</span
        >
      </div>
    </AppBtn>

    <ActionModal
      v-model="confirmLogout"
      header="Are you sure you want to logout?"
      description="click 'confirm' to proceed"
      @confirm="logout"
    ></ActionModal>
  </div>
</template>

<script>
import AppIcon from '@/shared/elements/AppIcon.vue';
import AppBtn from '../../../shared/elements/AppBtn.vue';
import AppMediaViewer from '../../../shared/elements/AppMediaViewer.vue';
import ActionModal from '../Modals/ActionModal.vue';
import _strings from '@/shared/mixins/_strings.js';

export default {
  components: { AppIcon, AppBtn, AppMediaViewer, ActionModal },

  mixins: [_strings],

  data() {
    return {
      confirmLogout: false,
      user: this.$store.getters['auth/user'],
    };
  },

  computed: {
    profile() {
      const user = this.user;
      if (user?.information) {
        return user.information;
      } else {
        return {
          first_name: 'No name specificed',
          last_name: '',
          company: '',
        };
      }
    },
    userType() {
      let groups = this.user.groups;
      let title = 'User';

      if (groups === null || groups.length === 0) {
        return null;
      }

      title = this.highestType(groups);

      const shouldAppendSuffix = ['vendor', 'buyer'].includes(
        title.toLowerCase()
      );

      if (shouldAppendSuffix) {
        title += ' User';
      }

      return title;
    },
  },

  methods: {
    logout() {
      this.$store.dispatch('cart/clearCart');
      this.$store.dispatch('cart/clearCheckout');
      this.$store.dispatch('auth/logout', {
        onLogout: () => {
          this.$router.push('/login');
          window.location.reload();
        },
      });
    },

    highestType(groups) {
      const groupNames = [
        'owner',
        'administrator',
        'vendor',
        'buyer',
        'estimator',
        'superintendent',
        'laborer',
      ];

      //Sorts the groups by index value compared to the above array
      const groupValues = groups
        .map((group) => {
          return groupNames.indexOf(group.name.toLowerCase());
        })
        .sort((a, b) => a - b);

      const highestTypeIndex = groupValues.find((a) => a > -1);
      const secondHighestTypeIndex = groupValues.find(
        (a) => a > -1 && a !== highestTypeIndex
      );

      if (groupValues.length > 0 && highestTypeIndex !== undefined) {
        const firstHighestType = groupNames[highestTypeIndex];
        const secondHighestType = groupNames[secondHighestTypeIndex];

        if (secondHighestType) {
          //Example: Administrator Vendor
          return `${firstHighestType} ${secondHighestType}`;
        }

        /**
         * Exceptional Case:
         * If 'owner' then prefix their persona
         * Example: Buyer Owner
         */
        if (firstHighestType === 'owner') {
          return `${this.user.persona} ${firstHighestType}`;
        }

        //Example: Administrator
        return `${firstHighestType}`;
      }

      return 'User';
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar-profile {
  &-picture {
    width: 60px;
    height: 60px;
    text-align: center;
    span {
      font-size: 50px;
    }
  }
}
</style>
